#deep {
    background: #ffffff;
    margin-top: 30px;
    padding-bottom: 66px;
    overflow: hidden;
}

#deep a,
#deep a:visited {
    color: #000000;

    font: 1.3vw 'Raleway', sans-serif;
}

#deep a:hover,
#deep a:focus {
    color: #000000;
}

#deep .divider {
    height: min(50px, 3vh);
}

#deep .text-divider {
    width: 50px;
    display: inline-block;
}

#deep h1 {
    font: 2.8vw 'Raleway', sans-serif;
    color: #000000;
    margin-bottom: 12px;
    text-align: center;
    font-weight: bold;
}

#deep h2 {
    font: 2.2vw 'Raleway', sans-serif;
    color: #000000;
    margin-bottom: 12px;
    text-align: center;
    font-weight: bold;
}

#deep h3 {
    font: 1.3vw 'Raleway', sans-serif;
    color: #000000;
    margin-bottom: 12px;
    text-align: center;
}

#deep p,
#deep ul li {
    font: 1.2vw 'Raleway', sans-serif;
    color: #000000;
    font-weight: 300;
    text-align: justify;
}

#deep .bold {
    font: 1.2vw 'Raleway', sans-serif;
    color: #000000;
    text-align: justify;
    font-weight: bold;
}

#deep .references {
    font: 0.8vw 'Raleway', sans-serif;
    color: #000000;
    font-weight: 300;
    text-align: justify;
}

#deep .logos {
    position: absolute;
}

#deep .logos .logo,
#deep .images-text {
    padding: 20px;
}

#deep ul {
    list-style-type: disc;
    margin-left: 30px;
}

#deep .return {
    position: absolute;
    left: 10px;
    top: 10px;
}

#deep table {
    font-size: medium;
}

#deep th,
td {
    padding: 5px;
}

